import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'

const POLICY = () => (
  <Layout><br />

    <Link to="/">トップへ戻る</Link>
<br />

    <h1>プライバシーポリシー</h1>
プライバシーポリシー <br />
本プライバシーポリシーは、uminek.ooo official（uminek.ooo 以下、「当サイト」とします。)の各種サービス（当サイトによる情報提供、各種お問合せの受付等）において、当サイトの訪問者（以下、「訪問者」とします。）の個人情報もしくはそれに準ずる情報を取り扱う際に、当サイトが遵守する方針を示したものです。<br />
1．基本方針<br />
当サイトは、個人情報の重要性を認識し、個人情報を保護することが社会的責務であると考え、個人情報に関する法令を遵守し、当サイトで取扱う個人情報の取得、利用、管理を適正に行います。当サイトで収集した情報は、利用目的の範囲内で適切に取り扱います。<br />
2．適用範囲<br />
本プライバシーポリシーは、当サイトにおいてのみ適用されます。<br />
3．個人情報の取得と利用目的<br />
当サイトで取得する訪問者の個人情報と利用目的、保存期間等は下記の通りです。<br />
3-1．お問い合せされた個人情報を取得します<br />
当サイトではお問い合わせフォーム（Contact）を設けています。<br />
訪問者がそのお問い合わせフォームから問い合わせをされた際に入力された、以下の個人情報を取得します。<br />
お問い合わせフォームに入力された名前（HN）<br />
お問い合わせフォームに入力されたメールアドレス<br />
お問い合わせフォームに入力されたお問合せ内容<br />
3-1-1．利用目的について<br />
お問い合わせ対応をするためと、訪問者の管理のためです。訪問者からのお問い合わせ情報を保存しておくことによって、同じ訪問者が別のお問い合わせをした際に、過去の問い合わせ内容を踏まえた対応をすることが出来、より的確な対応をすることが出来ます。<br />
3-1-2．保存期間について<br />
お問い合わせフォームに入力された個人情報は、3年間保存します。<br />
3-1-3．個人情報取得の同意について<br />
当サイトでは、お問い合わせフォームからお問い合わせをする前に、当プライバシーポリシーをご一読いただくよう案内しています。<br />
お問い合わせをされた時点で、その訪問者は当プライバシーポリシーに同意されたとみなします。<br />
3-2．Cookieによる個人情報の取得<br />
当サイトは、訪問者のコンピュータにCookieを送信することがあります。<br />
Cookie（クッキー）とは、ウェブサイトを利用したときに、ブラウザとサーバーとの間で送受信した利用履歴や入力内容などを、訪問者のコンピュータにファイルとして保存しておく仕組みです。<br />
3-2-1．利用目的について<br />
訪問者の当サイト閲覧時の利便性を高めるためです。<br />
たとえば、次回同じページにアクセスするとCookieの情報を使って、ページの運営者は訪問者ごとに表示を変えることができます。<br />
たとえばあるサイトを利用していて、初回はログインパスワードを入力する画面が表示されたけど、2回目以降はログイン画面は表示されずにアクセスできた、という経験ありませんか？それはCookieによるものです。<br />
訪問者がブラウザの設定でCookieの送受信を許可している場合、ウェブサイトは、訪問者のブラウザからCookieキーを取得できます。<br />
なお、訪問者のブラウザはプライバシー保護のため、そのウェブサイトのサーバーが送受信したCookieのみを送信します。<br />
3-2-2．Cookie情報の送受信の許可・拒否について<br />
訪問者は、Cookieの送受信に関する設定を「すべてのCookieを許可する」、「すべてのCookieを拒否する」、「Cookieを受信したらユーザーに通知する」などから選択できます。設定方法は、ブラウザにより異なります。Cookieに関する設定方法は、お使いのブラウザの「ヘルプ」メニューでご確認ください。<br />
すべてのCookieを拒否する設定を選択されますと、認証が必要なサービスを受けられなくなる等、インターネット上の各種サービスの利用上、制約を受ける場合がありますのでご注意ください。<br />
4．個人情報の管理<br />
当サイトは、訪問者からご提供いただいた情報の管理について、以下を徹底します。<br />
4-1. 情報の正確性の確保<br />
訪問者からご提供いただいた情報については、常に正確かつ最新の情報となるよう努めます。<br />
4-2. 安全管理措置<br />
当サイトは、個人情報の漏えいや滅失又は棄損を防止するために、適切なセキリュティ対策を実施して個人情報を保護します。<br />
4-3. 個人情報の廃棄<br />
個人情報が不要となった場合には、すみやかに廃棄します。<br />
4-4. 個人情報の開示、訂正、追加、削除、利用停止<br />
訪問者ご本人からの個人情報の開示、訂正、追加、削除、利用停止のご希望の場合には、ご本人であることを確認させていただいた上、速やかに対応させていただきます。<br />
上記を希望される場合は、以下のメールアドレスまでお問い合わせください。<br />
メールアドレス：info.uminek.ooo(あっと)gmail.com（あっと を@に置き換えて下さい）<br />
5．個人情報の第三者への提供について<br />
当サイトは、訪問者からご提供いただいた個人情報を、訪問者本人の同意を得ることなく第三者に提供することはありません。また、今後第三者提供を行うことになった場合には、提供する情報と提供目的などを提示し、訪問者から同意を得た場合のみ第三者提供を行います。<br />
6．未成年の個人情報について<br />
未成年者がお問い合わせフォームから問い合わせをする場合は必ず親権者の同意を得るものとし、お問い合わせをされた時点で、当プライバシーポリシーに対して親権者の同意があるものとみなします。<br />
7．お問い合わせ先<br />
当サイト、又は個人情報の取扱いに関しては、下記のメールアドレスにてお問い合わせください。<br />
当サイト運営者：uminek.ooo/うみねこ<br />
メールアドレス：info.uminek.ooo(あっと)gmail.com（あっと を@に置き換えて下さい）<br />
8．アクセス解析ツールについて<br />
当サイトでは、Googleによるアクセス解析ツール「Googleアナリティクス」を利用しています。<br />
このGoogleアナリティクスはアクセス情報の収集のためにCookieを使用しています。このアクセス情報は匿名で収集されており、個人を特定するものではありません。<br />
GoogleアナリティクスのCookieは、26ヶ月間保持されます。この機能はCookieを無効にすることで収集を拒否することが出来ますので、お使いのブラウザの設定をご確認ください。<br />
Googleアナリティクスの利用規約に関して確認したい場合は、<br />
<a target="_blank" rel="noopener noreferrer" href="https://www.google.com/analytics/terms/jp.html">ここをクリック</a>
してください。また、「ユーザーが Google パートナーのサイトやアプリを使用する際の Google によるデータ使用」に関して確認したい場合は、
<a target="_blank" rel="noopener noreferrer" href="https://policies.google.com/technologies/partner-sites?hl=ja">ここをクリック</a>
してください。<br />
9．プライバシーポリシーの変更について<br />
当サイトは、個人情報に関して適用される日本の法令を遵守するとともに、本プライバシーポリシーの内容を適宜見直しその改善に努めます。修正された最新のプライバシーポリシーは常に本ページにて開示されます。<br />
平成31年3月20日　策定<br /><br />

    <Link to="/">トップへ戻る</Link>
  </Layout>
)

export default POLICY
